<template>
  <section class="d-flex justify-center align-center fill-height">
    <LoaderCircular
      v-if="loader"
      :size="44"
      show
    />
    <div
      v-if="!loader"
      class="headmaster_register_container"
    >
      <PreexistWelcome
        v-if="inStep(1)"
        :role-id="roleId"
        :partner-id="partnerId"
        @nextStep="nextStep"
      />
      <PreexistPrivacyPolicy
        v-if="inStep(2)"
        :role-id="roleId"
        :partner-id="partnerId"
        @nextStep="nextStep"
        @backStep="backStep()"
      />
      <PreexistSetPassword
        v-if="inStep(3)"
        :role-id="roleId"
        :campus-code="campusCode"
        @nextStep="nextStep"
        @backStep="backStep()"
      />
      <PreexistConfirmInfo
        v-if="inStep(4) && roleId === 2"
        :role-id="roleId"
        @nextStep="toMap()"
        @backStep="backStep()"
      />
      <div v-if="inStep(5)" class="w-full d-flex h-full">
        <iframe :src="CONFIG.researchUrl" title="research-iframe" />
      </div>
    </div>
  </section>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import PreexistConfirmInfo from '@/components/organisms/preexist_register/PreexistConfirmInfo.vue';
import PreexistPrivacyPolicy from '@/components/organisms/preexist_register/PreexistPrivacyPolicy.vue';
import PreexistSetPassword from '@/components/organisms/preexist_register/PreexistSetPassword.vue';
import PreexistWelcome from '@/components/organisms/preexist_register/PreexistWelcome.vue';
import { mapActions, mapGetters } from 'vuex';
import CONFIG from '@/config';

export default {
  name: 'PreexisteLoginFlow',
  components: {
    PreexistWelcome,
    PreexistPrivacyPolicy,
    LoaderCircular,
    PreexistSetPassword,
    PreexistConfirmInfo,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    campusCode: {
      type: String,
      default: null,
    },
    roleId: {
      type: Number,
      default: 3,
    },
    partnerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      stepStack: [1],
      loader: true,
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      partnershipLabels: 'options/partnershipLabels',
    }),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.restartProcess(newVal);
      },
    },
    partnershipLabels: {
      handler(newVal) {
        if (newVal) {
          this.loader = false;
        }
      },
    },
  },
  mounted() {
    if (this.partnershipLabels) {
      this.loader = false;
    }
    this.getCampusWithCampusCode({ campusCode: this.campusCode });
    this.restartProcess();
  },
  methods: {
    ...mapActions({
      getCampusWithCampusCode: 'elasticSearch/getCampusWithCampusCode',
    }),
    toMap() {
      return this.$router.push('/map');
    },
    nextStep() {
      const nextStep = this.currentStep + 1;
      this.stepStack.push(nextStep);
    },
    backStep() {
      this.stepStack.pop();
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    restartProcess(customStep) {
      const newStep = customStep !== undefined ? customStep : this.step;
      this.stepStack = [newStep];
    },
  },
};
</script>
